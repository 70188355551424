<template>
  <div class="app-container">
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" stripe size="small" style="width: 100%;">
      <el-table-column prop="id" label="编号"/>
      <el-table-column label="链接类型">
        <template slot-scope="scope">
          <span>{{getlinkType(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="linkName" label="名称"/>
      <el-table-column prop="link" label="链接"/>
      <el-table-column prop="updateTime" label="更新日期">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.updateTime) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="180px" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['ADMIN','TLinkConfig_ALL','TLinkConfig_EDIT'])"
            style="margin-left: 3px"
            type="primary"
            size="mini"
            @click="editHandle(scope.row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <domain-toggle-dialog
      ref="toggledialog"
      :row-data="rowData"
      @success-edit="successEditHandle"
    ></domain-toggle-dialog>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
import domainToggleDialog from '@/components/system/domainToggle/form'
export default {
  name:'timing',
  components: {
    domainToggleDialog
  },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      rowData: {
        linkType: '',
        link:''
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = 'crm/tLinkConfig'
      const sort = 'id,desc'
      this.params = { page: this.page, size: this.size, sort: sort }
      return true
    },
    editHandle(row) {
      this.$refs.toggledialog.dialog = true;
      this.rowData = row;
    },
    successEditHandle(){
      this.init();
    },
    getlinkType(row){
      return row.linkType == 1 ? '官网': '个人中心';
    }
  }
}
</script>

<style scoped>

</style>
