<template>
  <el-dialog
    :visible.sync="dialog"
    title="编辑"
    append-to-body width="500px"
  >
    <p><span>链接类型: </span>{{getlinkType(rowData)}}</p>
    <p><span>原链接: </span>{{rowData.link}}</p>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      inline
      hide-required-asterisk
    >
      <el-form-item label="新链接" prop="selfLink">
        <el-input
          style="width: 300px;"
          placeholder="请输入"
          v-model="form.selfLink"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { updateDomain } from '@/api/system/domainToggle'
  export default {
    props: {
      rowData: {
        type: Object,
        default(){
          return {
            linkType: '',
          }
        }
      }
    },
    data() {
      return {
        loading: false,
        dialog: false,
        form: {
          selfLink: ''
        },
        rules: {
          selfLink: [
            { required: true, message: '请输入链接', trigger: 'blur' }
          ],
        }
      }
    },
    methods: {
      cancel() {
        this.resetForm()
      },
      doSubmit() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.loading = true
            this.doEdit();
          } else {
            return false
          }
        })
      },
      doEdit() {
        updateDomain({
          id: this.rowData.id,
          link: this.form.selfLink
        }).then(res => {
          this.resetForm()
          this.$notify({
            title: '修改成功',
            type: 'success',
            duration: 2500
          })
          this.loading = false;
          this.$emit('success-edit')
        }).catch(err => {
          this.loading = false
          console.log(err.response.data.message)
        })
      },
      resetForm() {
        this.dialog = false
        this.$refs['form'].resetFields()
        this.form = {
          selfLink: ''
        }
      },
      getlinkType(row){
        return row.linkType == 1 ? '官网': '个人中心';
      }
    }
  }
</script>

<style scoped>

</style>
